<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" md="4">
        <v-card class="pb-5" height="343">
          <v-list-item>
            <v-list-item-content class="text-center">

              <v-list-item-title class="title text-center">
                <img  class="mt-5 user-avatar-large" src="https://cdn.r10.net/image.php?u=120633&dateline=" alt="">
              </v-list-item-title>
              <v-list-item-subtitle>
                <h2 class="text-center">{{$t('Welcome')}} {{$store.state.user.userName}} </h2>
                <v-icon @click="common.logOut()">mdi-power</v-icon>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>

      </v-col>
      <v-col cols="12" md="8" class="pt-0">
        <v-row>
          <v-col cols="12" md="4" v-for="(item,index) in items" :key="index">
            <v-card class="pa-5 card-hover-primary-bg fill-height align-center d-flex" :to="item.link" height="160">
              <v-row align="center">
                <v-col cols="8">
                  <h3 class="text-uppercase mt-2">{{item.title}}</h3>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-icon large>{{item.icon}}</v-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import common from "../../../utils/common";
  export default {
    name: "index",
    data () {
      return{
        common,
        items: [
          {title: this.$t('join sport & activates'), icon: 'mdi-tennis', link: `/الاأنشطة-الرياضية/`},
          {title: this.$t('Schedule'), icon: 'mdi-calendar', link: '/schedule'},
          {title: this.$t('Dues'), icon: 'mdi-account-cash-outline', link: '/dues'},
          {title: this.$t('Book an event'), icon: 'mdi-book-check-outline', link: '/book-event'},
          {title: this.$t('Book your court'), icon: 'mdi-basketball-hoop-outline', link: '/book-court'},
          {title: this.$t('manage my account'), icon: 'mdi-account', link: '/manage-account'},
        ],
      }
    }
  }
</script>

<style scoped>
.user-avatar-large {
  height: 220px;
}
</style>
