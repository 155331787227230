import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)


const systemBase = process.env.VUE_APP_SYSTEM_API_BASE
const websiteBase = process.env.VUE_APP_WEBSITE_API_BASE
const userToken = localStorage.getItem('userToken')

Vue.prototype.$http_website = axios.create({
  baseURL: `${websiteBase}${document.documentElement.lang === 'en-US' ? 'en' : document.documentElement.lang}/`,
})


Vue.prototype.$http_website_without_lang = axios.create({
  baseURL: `${websiteBase}/`,
})

Vue.prototype.$http_sys = axios.create({
  baseURL: systemBase,
  headers: {
    'Authorization' : `Bearer ${userToken}`,
    'Content-Type': 'application/json'
  }
})

Vue.prototype.$http_sys_no_auth = axios.create({
  baseURL: systemBase,
  headers: {
    'Content-Type': 'application/json'
  }
})



