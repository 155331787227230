<template>
  <div>
    <h1>{{$t('Coming Soon')}}</h1>
  </div>
</template>

<script>
  export default {
    name: "comingSoon"
  }
</script>

<style scoped>

</style>
