<template>
<div>
  <v-row>
    <v-col cols="12" md="6">
      <v-select
        outlined
        v-model="selectedLvl"
        width="400"
        @change="getSchedule(selectedLvl)"
        :items="sportData.levels"
        item-text="name"
        item-value="group_category_code"
        id="family-select"
        attach
        auto
      >
      </v-select>
    </v-col>
    <v-col cols="12" md="6" class="text-md-right">

    </v-col>
  </v-row>
  <v-card class="pa-5 mb-3 sport-card" v-for="(day,propertyName) in scheduleDataGrouped" :key="propertyName">

      <h4 class="text-uppercase mb-5">{{propertyName}}</h4>
    <v-row v-for="(item,index) in day" :key="index">
      <v-col cols="12" md="6">
        <p>{{item.groupName}}</p>
      </v-col>
      <v-col cols="12" md="6" class="text-md-right">
        <p class="text--primary">{{common.convert24to12(item.fromTime.hours , item.fromTime.minutes)}} - {{common.convert24to12(item.toTime.hours ,item.toTime.minutes)}}</p>
      </v-col>
    </v-row>
  </v-card>
  <v-card  v-if="!scheduleData.length" class="pa-5 mb-3 sport-card" >
    {{$t('No Data Found')}}
  </v-card>
</div>
</template>

<script>
  import common from "../../../../utils/common";
  import _ from 'lodash'
  export default {
    name: "singleActivitySchedule",
    mounted() {
      let groupCategoryCode = parseInt(this.$route.query.group_category_code)
      this.getSchedule(groupCategoryCode)
      this.getSportData()
      this.selectedLvl = groupCategoryCode
    },
    data() {
      return {
        scheduleData: [],
        scheduleDataGrouped: {},
        common,
        sportData: [],
        selectedLvl: null
      }
    },
    methods: {
      getSchedule(groupCategoryCode ){
        this.$store.dispatch('loader', true)
        this.scheduleData = []
        this.$http_sys.post('Schedule/GetAllSchedules', {
          sportActivityID: parseInt(this.$route.query.sport_code),
          groupCategoryID: groupCategoryCode,
          lang: this.$i18n.locale
        })
        .then(response=>{
          this.scheduleData = response.data
          this.scheduleDataGrouped =   _.groupBy(this.scheduleData,"dayName")
          this.$store.dispatch('loader', false)
        })
        .catch(error=>{
          this.$store.dispatch('loader', false)
        })
      },
      getSportData () {
        this.$store.dispatch('setLoader', true)
        let code = this.$route.query.sport_code
        this.$http_website.get(`/single-sport/?code=${code}`)
          .then(response=>{
            this.sportData = response.data
            this.$store.dispatch('setLoader', false)
          })
          .catch(error=>{
            this.$store.dispatch('setLoader', false)
            if (error.response.status === 401) {
              common.logOut()
            }
          })
      },
    }
  }
</script>

<style scoped>

</style>
