import store from '../store/index'
export default class common {
  static formatDateTime (Data) {
    const formattedDateTime = Data
    return {
      date: formattedDateTime.getDate(),
      time: formattedDateTime.getTime()
    }
  }

  static convert24to12 (hours , minutes){
    if (hours > 12) {
      return `${24 - hours}:${minutes} PM`
    } else {
      return  `${hours}:${minutes} AM`
    }
  }

  static getDate (isoDate) {
    return isoDate.substring(0,10)
  }

  static logOut () {
    store.dispatch('setUserToken', null)
    localStorage.setItem('userToken','')
    store.dispatch('setUserID', null)
    localStorage.setItem('userId','')
    store.dispatch('setUserName', null)
    localStorage.setItem('userName','')
    window.location.href = "/"
  }

  static groupWeeks (data) {
    _.chain(data)
      // Group the elements of Array based on `color` property
      .groupBy("color")
  }

  static getPramsBeforeHash() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams
  }


}
