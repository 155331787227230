<template>
  <div>
    <v-card class="pa-5">
      <h3 class=" text-uppercase">{{$t('Book an event')}}</h3>
      <div class="pb-13">
        <v-row>
          <v-col cols="12" md="4" v-for="(item,index) in eventsList" :key="index">
              <div class="overlay-card" @click="bookDialog(item)">
                <img :src="item.image" alt="">
                <div class="overlay-card-content">
                  <h2 class="text--white">{{item.title}}</h2>
                </div>
              </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('Book')}} {{ selectedEvent.title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="bookForm">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="bookObject.date"
                    :placeholder="$t('Date')"
                    readonly
                    :rules="[v=> !!v || $t('Date is required')]"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="bookObject.date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                v-model="bookObject.number_of_attendance"
                outlined
                :rules="[v=> !!v || $t('Number of attendance is required')]"
                type="number"
                :placeholder="$t('Number Of Attendance')"
              ></v-text-field>
              <v-text-field
                v-model="bookObject.mobile"
                outlined
                :rules="[v=> !!v || $t('Mobile is required')]"
                :placeholder="$t('Mobile')"
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            {{$t('Cancel')}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="bookEvent"
          >
            {{$t('Book')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "index",
    data () {
      return {
        dialog: false,
        selectedEvent: {},
        menu2: false,
        eventsList: [
          {
            name: 'Birthday Party',
            value: 'birthday',
            icon: 'mdi-baby-face-outline'
          },
          {
            name: 'Weeding Party',
            value: 'wedding',
            icon: 'mdi-heart-multiple-outline'
          },
          {
            name: 'Graduation Party',
            value: 'graduation',
            icon: 'mdi-school-outline'
          },
        ],
        bookObject: {
          user_name: "",
          event_id: null,
          date: "",
          number_of_attendance: 3,
          mobile: ""
        }
      }
    },
    mounted() {
      this.getEvents()
    },
    methods: {
      bookDialog(item) {
        this.selectedEvent = item
        this.dialog = true
      },
      getEvents () {
        this.$store.dispatch('setLoader',true)
        this.$http_website.get('events-booking')
        .then(response=>{
          this.$store.dispatch('setLoader',false)
          this.eventsList = response.data
        })
        .catch(error=>{
          this.$store.dispatch('setLoader',false)
        })
      },
      bookEvent() {
        if (this.$refs.bookForm.validate()) {
          this.bookObject.event_id = this.selectedEvent.ID
          this.$http_website.post('book-event',this.bookObject)
          .then(response=>{
            this.dialog = false
            this.$store.dispatch('setSnackBarMessage', this.$t('Submitted Successfully'))
          })
          .catch(error=>{
            this.dialog = false
            this.$store.dispatch('setSnackBarMessage',error.response.data.message)
          })
        }
      }
    }
  }
</script>

<style scoped>

</style>
