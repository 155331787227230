import Vue from 'vue'
import store from './store'
import Router from 'vue-router'
import Home from "./components/views/Home";
import Register from './components/views/user/register'
import Schedule from './components/views/Schedule'
import Dues from './components/views/Dues'
import BookEvent from './components/views/BookEvent'
import BookCourt from './components/views/BookCourt'
import manageAccount from "./components/views/user/manageAccount";
import login from "./components/views/user/login";
import resetPassword from "./components/views/user/resetPassword";
import validateResetPassword from "./components/views/user/validateResetPassword";
import UPG from "./components/payment/UPG";
import activitesList from "./components/views/Schedule/allActivites/activitesList";
import singleActivity from "./components/views/Schedule/allActivites/singleActivity";
import singleActivitySchedule from "./components/views/Schedule/allActivites/singleActivitySchedule";
import myScheduleTable from "./components/views/Schedule/scheduleTables/myScheduleTable";
import familySchduleTable from "./components/views/Schedule/scheduleTables/familySchduleTable";
import comingSoon from "./components/views/ComingSoon/comingSoon";
import paymentOptions from "./components/views/webview/paymentOptions";
Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: paymentOptions,
      meta: { layout: 'no-sidebar'}
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: { layout: 'no-sidebar'}
    },

  ]
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  // if (!store.state.user.userToken && to.path !== '/login' && to.path !== '/forget-password' && to.path !== '/validate-password' && to.path !== '/register') {
  //   return next('/login');
  // }
  //
  // if (store.state.user.userToken && to.path === '/login') {
  //   return next('/');
  // }
  
  next();
})

export default router
