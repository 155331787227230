import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: document.documentElement.lang === 'ar',
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {

      light: {
        primary: '#00b188',
        secondary: '#363636'
      },
    },
  },
});
