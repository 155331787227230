export const helpers = {
    state: {
        snackbarMessage:'',
        loader: false,
        variables: {}
    },
    mutations: {
        SET_SNACKBAR_MESSAGE (state,snackbarMessage) {
            state.snackbarMessage = snackbarMessage
            setTimeout(()=>{
                state.snackbarMessage = ''
            },5000)
        },
        SET_LOADER(state,loader) {
            state.loader = loader
        },
        SET_VARIABLES(state,variables) {
            state.variables = variables
        }
    },
    actions: {
        setSnackBarMessage({commit},snackbarMessage) {
            commit('SET_SNACKBAR_MESSAGE', snackbarMessage)
        },
        setLoader({commit},loader) {
            commit('SET_LOADER', loader)
        },
        setVariables({commit},variables) {
            commit('SET_VARIABLES', variables)
        }
    }
}
