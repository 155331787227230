<template>
  <div>
    <v-main>
    <v-row class="ma-0">
      <v-col cols="12" md="3"><sidebar/></v-col>
      <v-col cols="12" md="9">
        <slot />
     </v-col>
    </v-row>
    </v-main>

    <page-loader></page-loader>
    <snackbar-message></snackbar-message>
  </div>
</template>

<script>
    import sidebar from '../components/layouts/sidebar'
    import pageLoader from '../components/common/loader'
    import snackbarMessage from '../components/common/snackBar'
    export default {
        name: "Default",
        components: {
          sidebar,
          pageLoader,
          snackbarMessage,

        },
        data () {
          return {
          }
        }
    }
</script>

<style scoped>

</style>
