<template>
  <v-app dark>
    <component :is="layout">
      <transition name="slide" mode="out-in">
        <router-view/>
      </transition>
    </component>
  </v-app>
</template>

<script>
  const default_layout = 'default'
  export default {
    name: 'App',
    data () {
      return {
        //
      }
    },
    beforeMount() {
      this.$i18n.locale = document.documentElement.lang
    },
    mounted() {
      this.$i18n.locale = document.documentElement.lang
      this.getVariables()
    },
    methods: {
      getVariables() {
        this.$http_website.get('attributes/')
        .then(response=>{
          this.$store.dispatch('setVariables',response.data)
        })
        .catch(error=>{

        })
      }
    },
    computed: {
      layout() {
        return this.$route.meta.layout+ '-layout'
      }
    }
  }
</script>

<style>

</style>
