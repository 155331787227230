import store from '../store/index'
export default class lsSync {
  static syncData () {
    const userToken = localStorage.getItem('userToken')
    let userName = localStorage.getItem('userName')
    let userId = localStorage.getItem('userId')
    store.dispatch('setUserToken', userToken)
    store.dispatch('setUserName', userName)
    store.dispatch('setUserID', userId)

  }
}
