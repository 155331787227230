<template>
  <div>
    <v-btn @click="callUPG()"> PAY NOW</v-btn>
  </div>
</template>

<script>
  export default {
    name: "UPG",
    mounted() {
      this.injectScript()
    },
    data () {
      return {
        orderId : '',
        paymentMethodFromLightBox: null,
        amount: 100,
        mID: 10725733672,
        tID: 79350073,
        trxdatetime: '20190807032800',
        secureKey: '83527af3ad3c6868de1f9b983690c01e58a31d1344e13a51d7585d6dba8f66cb'
      }
    },
    methods: {
      injectScript () {
        let upgScript = document.createElement('script')
        upgScript.setAttribute('src', 'https://upgstaglightbox.egyptianbanks.com/js/Lightbox.js')
        document.head.appendChild(upgScript)
      },
      callUPG() {

        Lightbox.Checkout.configure = {
          OrderId: this.orderId,
          paymentMethodFromLightBox: this.paymentMethodFromLightBox,
          MID: this.mID,
          TID: this.tID,
          AmountTrxn: this.amount,
          SecureHash: '6883edd7fb3f922ea4a6afa9be41dee892c1d148b19c38480a06dae119116302',
          TrxDateTime: this.trxdatetime,
          MerchantReference: "",
          completeCallback: function(data) {
            console.log('completed');
            console.log(data);
          },
          errorCallback: function(error) {
            console.log(error);
          },
          cancelCallback: function() {
            console.log('cancel');
          }
        };
        Lightbox.Checkout.showLightbox();
      }

    }
  }
</script>

<style scoped>

</style>
