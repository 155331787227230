<template>
    <div>
<!--        <cib class="d-block" :user-id="userId" :user-token="userToken" :value="totalValue" :saveids="paymentIds"/>-->
<!--        <fawry class="d-block mt-25"/>-->
        <qnb class="d-block" :user-id="userId" :user-token="userToken" :value="totalValue" :saveids="paymentIds" />
    </div>
</template>

<script>
    import cib from "../../payment/cib";
    import fawry from "../../payment/fawry";
    import qnb from "../../payment/qnb";
    export default {
        name: "payment-options",
        components: {
            cib,
            fawry,
            qnb
        },
        data() {
            return {
                totalValue: 0,
                paymentIds: [],
                userToken: '',
                userId: ''
            }
        },
        mounted() {
            let queryParams =  new URLSearchParams(window.location.search)
            this.userId = queryParams.get('userId');
            localStorage.setItem('userToken', queryParams.get('userToken'));
            this.totalValue = queryParams.get('totalValue');
            let paymentIds = queryParams.get('paymentIds')
            if (paymentIds) {
                this.paymentIds = paymentIds.split(',').map(Number);
            }
        },
        methods:{

        }
    }
</script>

<style>
.payment-btn {
    height: 300px !important;
}
</style>
