<template>
  <v-btn elevation="0"  @click="FawryPayment()" block height="200" class="mt-3" :loading="loader">
    <img src="https://fawry.com/wp-content/uploads/2020/08/fawry-pay-english-logo-11.png" height="100">
  </v-btn>
</template>

<script>
  export default {
    name: "fawry",
    props: {
      value: {
        type: Number,
        default: ()=> {
          return 0
        }
      }
    },
    data(){
      return {
        chargeRequest: {},
        item: {},
        time: new Date(),
        folderUrl: `${process.env.VUE_APP_WEBSITE_URL}wp-content/themes/whistle-child/vue-app`,
        loader: false
      }
    },
    mounted() {
      this.checkoutFawryScript();
    },
    methods: {
      checkoutFawryScript () {
        let checkoutFawryScript = document.createElement('script')
        checkoutFawryScript.setAttribute('src', 'https://atfawry.fawrystaging.com/ECommercePlugin/scripts/FawryPay.js')
        document.head.appendChild(checkoutFawryScript)
      },
      FawryPayment(){
        this.loader = true
        this.chargeRequest.language= 'ar-eg';
        this.chargeRequest.merchantCode= '1tSa6uxz2nRu3yG+kcSzGQ==';
        this.chargeRequest.merchantRefNumber= this.time.getTime();
        this.chargeRequest.customer = {};
        this.chargeRequest.customer.name = '';
        this.chargeRequest.customer.mobile = '';
        this.chargeRequest.customer.email = '';
        this.chargeRequest.customer.customerProfileId = '';
        this.chargeRequest.order = {};
        this.chargeRequest.order.description = 'test order';
        this.chargeRequest.order.expiry = '';
        this.chargeRequest.order.orderItems = [];

        this.item.productSKU = this.time.getTime();
        this.item.description ='Test Product';
        this.item.price = this.value;
        this.item.quantity ='1';
        this.item.width ='';
        this.item.height ='';
        this.item.length ='';
        this.item.weight ='1';
        this.chargeRequest.order.orderItems.push(this.item);
        this.chargeRequest.signature = '782810f59532ac2bd8c4c9815eb33a097f5f52df0f73921cdc44acd7979aedb8';

        FawryPay.checkout(this.chargeRequest,'','');

        this.$http_website_without_lang.get('/fawry-payment/?amount='+this.value+'&currency=EGP').
        then(response => {
          console.log('--->'+response);
          }).catch(err => {
          console.log('--->'+err);
        });
      }
    },
     computed: {
       fawryLogoUri() {
        return  require('../../assets/fawry.png');
      }
    }
  }
</script>

<style scoped>

</style>
