<template>
  <v-btn elevation="0"  @click="callPaymentApi()" block height="200" :loading="loader">
    <img src="https://seeklogo.com//images/C/cib-logo-A50E7B4A6B-seeklogo.com.png" height="100">
  </v-btn>
</template>

<script>
  import common from "../../utils/common";
  export default {
    name: "cib",
    props: {
      value: {
        type: Number,
        default: ()=> {
          return 0
        }
      },
      saveids: {
        type: Array,
        default: ()=> {
          return []
        }
      },
      userId: {
        type: String,
        default: ()=>{
          return ''
        }
      },
      userToken: {
        type: String,
        default: ()=>{
          return ''
        }
      }
    },
    data(){
      return {
        apiResponse: {},
        order_id: localStorage.order_id,
        folderUrl: `${process.env.VUE_APP_WEBSITE_URL}wp-content/themes/whistle-child/vue-app`,
        currentUrl: `${process.env.VUE_APP_WEBSITE_URL}`,
        resultIndicator: '',
        loader: false
      }
    },
    mounted() {
      console.log(this.userId)
      let queryParams =  new URLSearchParams(window.location.search)
      this.resultIndicator = queryParams.get('resultIndicator');
      if (this.resultIndicator) {
        this.checkPayment()
      }
      this.checkoutScript();
    },
    methods: {
      checkoutScript () {
        let checkoutScript = document.createElement('script')
        checkoutScript.setAttribute('src', 'https://cibpaynow.gateway.mastercard.com/checkout/version/55/checkout.js')
        checkoutScript.setAttribute('data-complete', this.currentUrl)
        document.head.appendChild(checkoutScript)
      },
      lightBoxPayment(){
        Checkout.configure({
          session: {
            id: this.apiResponse.response.session.id
          },
          merchant: 'TESTCIB700899',
          order: {
            amount: this.value,
            currency: 'EGP',
            description: 'Ordered goods',
            id: this.apiResponse.order_id
          },
          interaction: {
            operation: 'PURCHASE', // set this field to 'PURCHASE' for <<checkout>> to perform a Pay Operation.
            merchant: {
              name: 'Sample Merchant',
              address: {
                line1: '200 Sample St',
                line2: '1234 Example Town'
              }
            }
          }
        });

        Checkout.showLightbox();
      },
      callPaymentApi(){
        this.loader = true
        this.$http_website_without_lang.post('/cib-payment/',{
          amount:this.value,
          currency:'EGP',
          saveIds: this.saveids,
          userID: this.userId,
        }).then(response => {
            this.apiResponse = response.data;
            this.order_id = response.data.order_id;
            localStorage.order_id = response.data.order_id;
            this.lightBoxPayment();
        }).catch(err => {
          console.log('--->'+err);
        })
      },
      checkPayment(){
        this.$http_website_without_lang.post('/check-payment/', {
          order_id: localStorage.order_id,
          result_indicator: this.resultIndicator,
          user_token: localStorage.getItem('userToken')
        }).
        then(response => {
          this.$emit('reloadDues')
                }
        ).catch(err => {
          console.log('error--->'+err);
        })
      }
    },
    computed: {
       cibLogoUri() {
        return  require('../../assets/cib.png');
      }
    }
  }
</script>

<style scoped>

</style>
