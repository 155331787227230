<template>
  <div>
    <v-card class="pa-5">
      <h3 class=" text-uppercase">{{$t('All Activities')}}</h3>
      <div>
       <v-row>
         <v-col cols="12" md="4" v-for="(sport,index) in sports" :key="index">
           <div class="overlay-card" @click="goRoute(sport.code)">
             <img :src="sport.image" alt="">
             <div class="overlay-card-content">
               <h2 class="text--white">{{sport.title}}</h2>
             </div>
           </div>
         </v-col>
       </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "activitesList",
    data () {
      return {
        sports: []
      }
    },
    mounted() {
      this.getAllSports()
    },
    methods: {
      getAllSports() {
        this.$store.dispatch('setLoader', true)
        this.$http_website.get('sports')
        .then(response=>{
          this.sports = response.data
          this.$store.dispatch('setLoader', false)
        })
        .catch(error=>{
          this.$store.dispatch('setLoader', false)
        })
      },
      goRoute(code) {
        this.$router.push({
          path: '/single-activity',
          query: {
            code
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>
