<template>
  <div>
    <v-card class="pa-5 book-court">
      <h3 class=" text-uppercase">{{$t('Book Your Court')}}</h3>


      <v-form ref="filterForm">
        <v-row class="ml-0 mr-0 mt-5 pa-0">
            <v-col cols="12" md="4" class="pt-0 pb-0 relative menuDate">
              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                attach=".menuDate"
                
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterObject.date"
                    :placeholder="$t('Date')"
                    readonly
                    :rules="[v=> !!v || $t('Date is required')]"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar-outline"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterObject.date"
                  @input="menuDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4" class="pt-0 pb-0 relative menuTimeFrom">
              <v-menu
                v-model="menuTimeFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                attach=".menuTimeFrom"

              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterObject.fromTime"
                    :placeholder="$t('Time From')"
                    readonly
                    :rules="[v=> !!v || $t('Time is required')]"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-clock-outline"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="filterObject.fromTime"
                  @input="menuTimeFrom = false"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col class="pt-0 pb-0 relative menuTimeTo" cols="12" md="4">
              <v-menu
                v-model="menuTimeTo"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                attach=".menuTimeTo"

              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterObject.toTime"
                    :placeholder="$t('Time to')"
                    readonly
                    :rules="[v=> !!v || $t('Time is required')]"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-clock-outline"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="filterObject.toTime"
                  @input="menuTimeTo = false"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col class="pt-0 pb-0"  cols="12" md="6" >
              <v-select
                outlined
                v-model="filterObject.sportActivityID"
                width="400"
                :items="sports"
                item-text="title"
                :placeholder="$t('Choose Sport')"
                item-value="code"
                id="family-select"
                attach
                auto
              >
              </v-select>
            </v-col>

            <v-col class="pt-0 pb-0"  cols="12" md="6">
              <v-btn color="primary" block height="40" @click="findCourt" elevation="0">{{$t('Find Court')}}</v-btn>
            </v-col>
        </v-row>
      </v-form>

        <div class="courts-result">
            <v-expansion-panels>
                <v-expansion-panel
                        v-for="(court,i) in courts"
                        :key="i"
                >
                    <v-expansion-panel-header>
                        {{court.courtName}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('Sport')}}</p>
                               <h4>Tennis</h4>
                          </v-col>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('Date')}}</p>
                              <h4>{{filterObject.date}}</h4>
                          </v-col>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('Court ID')}}</p>
                              <h4>{{court.courtID}}</h4>
                          </v-col>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('Start Time')}}</p>
                              <h4>{{filterObject.fromTime}}</h4>
                          </v-col>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('End Time')}}</p>
                              <h4>{{filterObject.toTime}}</h4>
                          </v-col>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('Duration')}}</p>
                              <h4>{{hours  }} {{$t('Hours')}}</h4>
                          </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>

    </v-card>
  </div>
</template>

<script>

  import common from "../../../utils/common";
  import moment from 'moment'
  export default {
    data() {
      return {
        menuDate: false,
        menuTimeFrom: false,
        menuTimeTo: false,
        sports: [],
        hours: 0,
        filterObject: {
          date: '',
          fromTime: '',
          toTime:'',
          sportActivityID: ''
        },
        courts: []
      }
    },
    mounted() {
     this.getAllSports()
    },
    methods: {
      getAllSports() {
        this.$store.dispatch('setLoader', true)
        this.$http_website.get('sports')
          .then(response=>{
            this.sports = response.data
            this.filterObject.sportActivityID = this.sports[0].code
            this.$store.dispatch('setLoader', false)
          })
          .catch(error=>{
            this.$store.dispatch('setLoader', false)
          })
      },
      findCourt() {
        if (this.$refs.filterForm.validate()) {
          this.$store.dispatch('setLoader',true)
            let startTime = moment(this.filterObject.fromTime, "HH:mm");
            let endTime = moment(this.filterObject.toTime, "HH:mm");

// calculate total duration
            this.hours = moment.duration(endTime.diff(startTime)).asHours()

          this.$http_sys.post('Court/GetCourtAvailable',{
            sportActivityID: this.filterObject.sportActivityID,
            startDate: `${this.filterObject.date}T${this.filterObject.fromTime}:00.000Z`,
            endDate: `${this.filterObject.date}T${this.filterObject.toTime}:00.000Z`,
            lang: this.$i18n.locale
          })
          .then(response=>{
            this.courts = response.data
            this.$store.dispatch('setLoader', false)
          })
          .catch(error=>{
            this.$store.dispatch('setLoader', false)
            if (error.response.status === 401) {
              common.logOut()
            }
          })
        }
      }
    }
  }
</script>

<style scoped>
.book-court {
  min-height: 700px;
}
</style>
