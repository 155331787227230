<template>
  <div>
    <v-card
      height="600"
      width="300"
      class="hidden-sm-and-down"
    >
      <v-navigation-drawer permanent width="300">
        <v-list-item>
          <v-list-item-content class="text-center">
            <v-list-item-title class="title text-center">
              <img class="user-avatar" src="https://cdn.r10.net/image.php?u=120633&dateline=" alt="">
            </v-list-item-title>
            <v-list-item-subtitle>
             <h4 class="text-center">{{$store.state.user.userName}} <v-icon @click="common.logOut()">mdi-power</v-icon></h4>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list
          dense
          nav
          class="mt-3"
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

    </v-card>
    <div class="hidden-md-and-up">
      <v-icon @click="drawer =! drawer">mdi-menu</v-icon>
      <v-navigation-drawer
        app
        persistent
        mobile-breakpoint="991"
        width="200"
        v-model="drawer"
      >
        <v-list-item>
          <v-list-item-content class="text-center">
            <v-list-item-title class="title text-center">
              <img class="user-avatar" src="https://cdn.r10.net/image.php?u=120633&dateline=" alt="">
            </v-list-item-title>
            <v-list-item-subtitle>
              <h4 class="text-center">{{$store.state.user.userName}} <v-icon @click="common.logOut()">mdi-power</v-icon></h4>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list
          dense
          nav
          class="mt-3"
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>

  </div>

</template>

<script>
  import common from "../../utils/common";
  export default {
    name: "sidebar",
    data () {
      return {
        common,
        drawer: false,
        items: [
          {title: this.$t('join sport & activates'), icon: 'mdi-tennis', link: '/الاأنشطة-الرياضية/'},
          {title: this.$t('Schedule'), icon: 'mdi-calendar', link: '/schedule'},
          {title: this.$t('Dues'), icon: 'mdi-account-cash-outline', link: '/dues'},
          {title: this.$t('Book an event'), icon: 'mdi-book-check-outline', link: '/book-event'},
          {title: this.$t('Book your court'), icon: 'mdi-basketball-hoop-outline', link: '/book-court'},
          {title: this.$t('manage my account'), icon: 'mdi-account', link: '/manage-account'},
        ],
        right: null,
      }
    }
  }
</script>

<style scoped>
.user-avatar {
  height: 150px;
}
</style>
