export const user = {
  state: {
    userToken: '',
    userName: '',
    userEmail: '',
    userId: ''
  },
  mutations: {
    'SET_USER_TOKEN' (state , token){
      state.userToken = token
      localStorage.setItem('userToken', token)
    },
    'SET_USER_NAME' (state, userName) {
      state.userName = userName
      localStorage.setItem('userName', userName)
    },
    'SET_USER_EMAIL' (state,userEmail) {
      state.userEmail = userEmail
      localStorage.setItem('userEmail', userEmail)
    },
    'SET_USER_ID' (state,userId) {
      state.userId = userId
      localStorage.setItem('userId', userId)
    }
  },
  actions: {
    setUserToken({commit},token){
      commit('SET_USER_TOKEN',token)
    },
    setUserName({commit},userName){
      commit('SET_USER_NAME',userName)
    },
    setUserEmail({commit},userEmail){
      commit('SET_USER_EMAIL',userEmail)
    },
    setUserID({commit},userId) {
      commit('SET_USER_ID', userId)
    }
  }
}
