<template>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6>
            <v-card class="pa-5">
              <div>
                <h2 CLASS="text--primary text-uppercase">{{$t('Register')}}</h2>
                <p class="caption">{{$t('Please fill the required data to create new account')}}</p>
              </div>
              <v-card-text class="pa-0">
                <v-form ref="registerForm">
                  <v-text-field
                    outlined
                    v-model="userObj.firstName"
                    :rules="[v=> !!v || $t('First name is required')]"
                    :placeholder="$t('First Name *')"
                    type="text">
                  </v-text-field>
                  <v-text-field
                    outlined
                    v-model="userObj.secondName"
                    :placeholder="$t('Second Name')"
                    type="text">
                  </v-text-field>
                  <v-text-field
                    outlined
                    v-model="userObj.familyName"
                    :rules="[v=> !!v || $t('Family name is required')]"
                    :placeholder="$t('Family Name *')"
                    type="text">
                  </v-text-field>
                  <v-text-field
                    outlined
                    v-model="userObj.membershipId"
                    :rules="[v=> !!v || $t('Member ship ID  is required')]"
                    :placeholder="$t('Member Ship ID *')"
                    type="text">
                  </v-text-field>
                  <v-menu
                    ref="dobMenu"
                    v-model="dobMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    class="max-500"
                    attach
                    auto
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="userObj.dateOfBirth"
                        :placeholder="$t('Date Of Birth *')"
                        :rules="[v=> !!v || $t('Date Of Birth is required')]"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="userObj.dateOfBirth"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="saveDob"
                    ></v-date-picker>
                  </v-menu>
                  <v-text-field
                    outlined
                    v-model="userObj.mobileNumber"
                    :rules="[v=> !!v || $t('Mobile number is required')]"
                    :placeholder="$t('Mobile number *')"
                    type="text">
                  </v-text-field>
                  <v-text-field
                    outlined
                    v-model="userObj.email"
                    :rules="[v=> !!v || $t('Email is required')]"
                    :placeholder="$t('Email *')"
                    type="text">
                  </v-text-field>
                  <v-select
                    outlined
                    v-model="userObj.gender"
                    :items="$store.state.helpers.variables.gender"
                    item-value="id"
                    item-text="name"
                    :rules="[v=> !!v || $t('Gender is required')]"
                    :placeholder="$t('Gender *')"
                    type="text"
                    attach
                    auto
                  >
                  </v-select>
                  <v-text-field
                    outlined
                    v-model="userObj.userName"
                    :rules="[v=> !!v || $t('User name is required')]"
                    :placeholder="$t('User name *')"
                    >
                  </v-text-field>
                  <v-text-field
                    outlined
                    v-model="userObj.password"
                    :rules="[v=> !!v || $t('Password is required')]"
                    :placeholder="$t('Password *')"
                    type="password">
                  </v-text-field>
                  <v-text-field
                    outlined
                    v-model="userObj.repeatPassword"
                    :rules="[v=> !!v || $t('Password confirmation is required') ,v=> v=== userObj.password || $t('Password dose not match') ]"
                    :placeholder="$t('Repeat Password *')"
                    type="password">
                  </v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="d-block text-center">
                <v-btn :loading="loader"  width="150" @click="register" elevation="0" color="primary">{{$t('Register')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
</template>

<script>
  export default {
    data() {
      return {
        dobMenu: false,
        userObj: {
          firstName: '',
          secondName: '',
          familyName: '',
          membershipId: '',
          dateOfBirth: '',
          mobileNumber: '',
          email: '',
          gender: 0,
          userName: '',
          password: '',
          repeatPassword:'',
          pictureURL: ''
        },
        loader: false
      }
    },
    methods: {
      register () {
        if (this.$refs.registerForm.validate()) {
          this.loader = true
          this.userObj.lang = this.$i18n.locale
          this.userObj.gender = parseInt(this.userObj.gender)
          this.$http_sys_no_auth.post('Authentication/Register',this.userObj)
          .then(response=>{
            this.$store.dispatch('setUserToken', response.data.auth_token)
            window.location.href = '/user/#/'
            this.loader = false
          })
          .catch(error=>{
            this.loader = false
            this.$store.dispatch('setSnackBarMessage',error.response.data.errorMessage)
          })
        }

      },
      saveDob (date) {
        this.$refs.dobMenu.save(date)
      },
    },
    watch: {
      dobMenu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    }
  }
</script>
<style>

</style>
